var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"section section-secondary jpadding"},[_c('div',{staticClass:"jcard jcard-main jcard-nohover"},[_c('div',{staticClass:"d-flex mb-3"},[_c('h3',[_vm._v("Service Catalogue")]),_c('div',{staticClass:"ml-auto"},[_c('button',{staticClass:"btn btn-outline-primary",on:{"click":_vm.showNewServiceDialog}},[_c('i',{staticClass:"fa fa-plus"})])])]),_c('SearchAndFilters',{staticClass:"d-flex mb-3",attrs:{"searchList":_vm.servicesList,"filters":_vm.filters}}),_c('div',{staticClass:"jblock jblock-grid"},_vm._l((_vm.servicesList),function(service,index){return _c('div',{key:service.id,staticClass:"jcard-medium"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h5',[_vm._v(_vm._s(service.name))]),_c('b-dropdown',{staticClass:"ml-auto",attrs:{"right":"","variant":"link","toggle-class":"jbtn jbtn-icon jbtn-icon-link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"fa fa-ellipsis-v"})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.showEditServiceDialog(index)}}},[_c('i',{staticClass:"fal fa-pencil mr-2"}),_vm._v(" Edit service")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteService(index)}}},[_c('i',{staticClass:"fal fa-trash mr-2"}),_vm._v(" Delete service")])],1)],1),_c('hr'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md"},[_c('p',[_c('small',[_vm._v("Service ID")]),_c('br'),_vm._v(_vm._s(service.id.substring(0, 8))+" ")])]),_c('div',{staticClass:"col-md"},[_c('p',[_c('small',[_vm._v("Watch")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.getModelName(service)))])])]),_c('div',{staticClass:"col-md"},[_c('p',[_c('small',[_vm._v("Status")]),_c('br'),_c('span',{staticClass:"badge badge-pill",class:{
                  'badge-success': service.active,
                  'badge-danger': !service.active,
                }},[_vm._v(_vm._s(service.active ? "ACTIVE" : "INACTIVE"))])])])]),_c('p',[_c('small',[_vm._v("Price")]),_c('br'),_vm._v(_vm._s(service.price)+"€")]),_c('hr'),_c('p',{staticStyle:{"white-space":"pre-wrap"}},[_c('small',[_vm._v(_vm._s(service.text))])])])}),0)],1)]),_c('b-modal',{ref:"new-service-modal",attrs:{"hide-header":true,"hide-footer":true,"id":"new-service-modal","centered":"","title":"BootstrapVue"}},[_c('div',{staticClass:"jdialog-top"},[_c('div',{staticClass:"close-container"},[_c('h3',[_c('strong',[_vm._v(_vm._s(_vm.editMode ? "Update" : "Create")+" Service")])]),_c('span',{staticClass:"ml-auto jclose",on:{"click":function($event){return _vm.$bvModal.hide('new-service-modal')}}},[_c('i',{staticClass:"fa fa-times"})])]),(_vm.editMode)?_c('p',[_c('small',[_vm._v("Update existing service in the catalogue.")])]):_c('p',[_c('small',[_vm._v("Add a new service to the catalogue.")])])]),_c('div',{staticClass:"jdialog-main"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fal fa-tag"}),_vm._v(" Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.name),expression:"editData.name"}],class:{
                'form-control': true,
                'is-invalid': !_vm.validInput(_vm.editData.name) && _vm.bluredName,
              },attrs:{"type":"text"},domProps:{"value":(_vm.editData.name)},on:{"blur":function($event){_vm.bluredName = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid name for the service. ")])])])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"model"}},[_c('i',{staticClass:"fal fa-watch"}),_vm._v(" Watch")]),_c('b-form-select',{attrs:{"options":_vm.watchModelNameOptions},model:{value:(_vm.editData.modelId),callback:function ($$v) {_vm.$set(_vm.editData, "modelId", $$v)},expression:"editData.modelId"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please select a valid watch. ")])],1)])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"price"}},[_c('i',{staticClass:"fal fa-euro-sign"}),_vm._v(" Price")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.price),expression:"editData.price"}],class:{
                'form-control': true,
                'is-invalid':
                  !_vm.validNonTxtField(_vm.editData.price) && _vm.bluredPrice,
              },attrs:{"type":"text"},domProps:{"value":(_vm.editData.price)},on:{"blur":function($event){_vm.bluredPrice = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "price", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid price. ")])])]),_c('div',{staticClass:"col-md"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"status"}},[_c('i',{staticClass:"fal fa-exclamation-circle"}),_vm._v(" Status")]),_c('b-form-select',{attrs:{"options":_vm.$C.getFormattedBoolOptionsArray(_vm.$C.STATUS.SERVICE_INDEX)},model:{value:(_vm.editData.active),callback:function ($$v) {_vm.$set(_vm.editData, "active", $$v)},expression:"editData.active"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please select a valid status. ")])],1)])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"text"}},[_c('i',{staticClass:"fal fa-comment-alt"}),_vm._v(" Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.editData.text),expression:"editData.text"}],class:{
            'form-control': true,
            'is-invalid': !_vm.validInput(_vm.editData.text) && _vm.bluredText,
          },attrs:{"type":"text"},domProps:{"value":(_vm.editData.text)},on:{"blur":function($event){_vm.bluredText = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editData, "text", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a short description text. ")])]),(_vm.showInputError)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('i',{staticClass:"fad fa-exclamation-circle"}),_vm._v(" "+_vm._s(_vm.validationMsg)+" ")]):_vm._e()],1),_c('div',{staticClass:"jdialog-bottom with-cancel"},[_c('button',{staticClass:"jbtn jbtn-sm jbtn-red",on:{"click":function($event){return _vm.$bvModal.hide('new-service-modal')}}},[_c('i',{staticClass:"fa fa-times"}),_vm._v(" Cancel ")]),_c('button',{staticClass:"jbtn jbtn-sm",attrs:{"disabled":_vm.isSaving},on:{"click":_vm.updateService}},[_c('i',{staticClass:"fa fa-check"}),_vm._v(" "+_vm._s(_vm.editMode ? "Update" : "Create")+" ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <!-- SECTION -->
    <section class="section section-secondary jpadding">
      <div class="jcard jcard-main jcard-nohover">
        <!-- TITLE -->
        <div class="d-flex mb-3">
          <h3>Service Catalogue</h3>
          <div class="ml-auto">
            <button class="btn btn-outline-primary" @click="showNewServiceDialog">
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <!-- Search -->
        <SearchAndFilters class="d-flex mb-3" :searchList=servicesList :filters=filters></SearchAndFilters>
        <div class="jblock jblock-grid">
          <!-- LIST -->
          <div v-for="(service, index) in servicesList" :key="service.id" class="jcard-medium">
            <div class="d-flex align-items-center">
              <h5>{{ service.name }}</h5>
              <!-- <button
                @click="showEditServiceDialog(index)"
                class="ml-auto jbtn-text jbtn-grey jbtn-circle-hover"
              >
                <i class="fa fa-ellipsis-v"></i>
              </button> -->
              <b-dropdown right variant="link" toggle-class="jbtn jbtn-icon jbtn-icon-link" class="ml-auto" no-caret>
                <template #button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item @click="showEditServiceDialog(index)"><i class="fal fa-pencil mr-2"></i> Edit
                  service</b-dropdown-item>
                <b-dropdown-item @click="deleteService(index)"><i class="fal fa-trash mr-2"></i> Delete
                  service</b-dropdown-item>
              </b-dropdown>
            </div>

            <!-- <div class="jdropdown">
              <button
                @click="dropdown1 = !dropdown1"
                class="jbtn-text jbtn-grey jbtn-circle-hover "
              >
                <i class="fa fa-ellipsis-v"></i>
              </button>

              <ul v-bind:class="{ hidden: !dropdown1 }" class="jdropdown-list">
                <li><i class="fal fa-pencil"></i> Edit</li>
                <li><i class="fal fa-trash"></i> Delete</li>
              </ul>
            </div> -->

            <hr />
            <div class="form-row">
              <div class="col-md">
                <p>
                  <small>Service ID</small><br />{{
                    service.id.substring(0, 8)
                  }}
                </p>
              </div>
              <div class="col-md">
                <p>
                  <small>Watch</small><br />
                  <span>{{ getModelName(service) }}</span>
                </p>
              </div>
              <div class="col-md">
                <p>
                  <small>Status</small><br />
                  <span class="badge badge-pill" v-bind:class="{
                    'badge-success': service.active,
                    'badge-danger': !service.active,
                  }">{{ service.active ? "ACTIVE" : "INACTIVE" }}</span>
                </p>
              </div>
            </div>
            <p><small>Price</small><br />{{ service.price }}€</p>
            <hr />
            <p style="white-space: pre-wrap">
              <small>{{ service.text }}</small>
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- ----- new/edit service modal --------------------------------- -->
    <b-modal :hide-header="true" :hide-footer="true" id="new-service-modal" ref="new-service-modal" centered
      title="BootstrapVue">
      <div class="jdialog-top">
        <div class="close-container">
          <h3>
            <strong>{{ editMode ? "Update" : "Create" }} Service</strong>
          </h3>
          <span @click="$bvModal.hide('new-service-modal')" class="ml-auto jclose"><i class="fa fa-times "></i></span>
        </div>
        <p v-if="editMode">
          <small>Update existing service in the catalogue.</small>
        </p>
        <p v-else><small>Add a new service to the catalogue.</small></p>
      </div>
      <div class="jdialog-main">
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="name"> <i class="fal fa-tag"></i> Name</label>
              <input type="text" v-bind:class="{
                  'form-control': true,
                  'is-invalid': !validInput(editData.name) && bluredName,
                }" v-on:blur="bluredName = true" v-model="editData.name" />
              <div class="invalid-feedback">
                Please enter a valid name for the service.
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md">
            <div class="form-group">
              <label for="model"><i class="fal fa-watch"></i> Watch</label>
              <b-form-select v-model="editData.modelId" :options="watchModelNameOptions"></b-form-select>
              <div class="invalid-feedback">
                Please select a valid watch.
              </div>

            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md">
            <div class="form-group">
              <label for="price"><i class="fal fa-euro-sign"></i> Price</label>
              <input type="text" v-bind:class="{
                  'form-control': true,
                  'is-invalid':
                    !validNonTxtField(editData.price) && bluredPrice,
                }" v-on:blur="bluredPrice = true" v-model="editData.price" />
              <div class="invalid-feedback">
                Please enter a valid price.
              </div>
            </div>
          </div>

          <div class="col-md">
            <div class="form-group">
              <label for="status"><i class="fal fa-exclamation-circle"></i> Status</label>
              <b-form-select v-model="editData.active"
                :options="$C.getFormattedBoolOptionsArray($C.STATUS.SERVICE_INDEX)"></b-form-select>
              <div class="invalid-feedback">
                Please select a valid status.
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="text"><i class="fal fa-comment-alt"></i> Description</label>
          <textarea type="text" v-bind:class="{
              'form-control': true,
              'is-invalid': !validInput(editData.text) && bluredText,
            }" v-on:blur="bluredText = true" v-model="editData.text" />
          <div class="invalid-feedback">
            Please enter a short description text.
          </div>
        </div>

        <!-- alert -->
        <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
          {{ validationMsg }}
        </b-alert>
      </div>

      <!-- dialog bottom -->
      <div class="jdialog-bottom with-cancel">
        <button @click="$bvModal.hide('new-service-modal')" class="jbtn jbtn-sm jbtn-red">
          <i class="fa fa-times"></i> Cancel
        </button>
        <button @click="updateService" class="jbtn jbtn-sm" :disabled="isSaving">
          <i class="fa fa-check"></i> {{ editMode ? "Update" : "Create" }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import firebase from "firebase/app";
import toast from "@/assets/js/toast";
import SearchAndFilters from "@/components/general/SearchAndFilters";
import C from "@/const";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;

export default {
  name: "ServicesList",
  components: {
    SearchAndFilters,
  },
  data() {
    return {
      servicesList: [],
      watchModels: new Map(),
      watchModelNameOptions: [],
      filters: [],

      isLoading: false,
      isSaving: false,

      //form error alert
      showInputError: false,
      validationMsg: "",

      //edit user info form
      editMode: false,
      valid: false,
      bluredName: false,
      bluredText: false,
      bluredPrice: false,

      serviceId: "",
      editData: {
        name: "",
        modelId: "",
        active: true,
        text: "",
        price: 0,
      },
    };
  },
  methods: {
    //load all services list
    allServices() {
      db.collection(C.COLLECTION.SERVICE_CATALOG).onSnapshot((querySnapshot) => {
        this.servicesList.splice(0);
        querySnapshot.forEach((doc) => {
          const docdata = doc.data();
          docdata.id = doc.id;
          docdata.statusName = docdata.active ? "active" : "inactive";
          this.servicesList.push(docdata);
        });

        if (this.filters && this.filters.length > 0)
          return;
        this.filters.push({
          title: "Price",
          field: "price",
          type: C.FILTER_TYPE.NUMBER_RANGE
        });
      });
    },
    // load all watch models
    allWatchModels() {
      db.collection(C.COLLECTION.WATCH_CATALOG).get()
        .then((querySnapshot) => {
          var firstModelId = null;
          querySnapshot.forEach((doc) => {
            const watchModel = doc.data();
            watchModel.id = doc.id;

            if (!firstModelId)
              firstModelId = watchModel.id;
            this.watchModels.set(watchModel.id, watchModel);
            this.watchModelNameOptions.push({ value: watchModel.id, text: watchModel.name });
          });
          if (firstModelId)
            this.editData.modelId = firstModelId;
        })
        .catch((error) => {
          toast.error("Could not load watch models! " + error);
          console.log(error);
        });
    },
    // show/init create service dialog
    showNewServiceDialog() {
      this.editMode = false;
      this.validationMsg = "";
      // reset valid fields
      this.bluredName = false;
      this.bluredText = false;
      this.bluredPrice = false;
      // reset data
      this.editData.name = "";
      this.editData.active = true;
      this.editData.text = "";
      this.editData.price = 0;
      // open dialog
      this.$refs["new-service-modal"].show();
    },
    // show/init edit service dialog
    showEditServiceDialog(index) {
      this.editMode = true;
      this.validationMsg = "";
      // load data
      this.serviceId = this.servicesList[index].id;
      this.editData.name = this.servicesList[index].name;
      this.editData.modelId = this.servicesList[index].modelId;
      this.editData.active = this.servicesList[index].active;
      this.editData.text = this.servicesList[index].text;
      this.editData.price = this.servicesList[index].price;
      // open dialog
      this.$refs["new-service-modal"].show();
    },
    
    // save catalogue service to db
    updateService() {
      if (!this.validate()) return;

      this.isSaving = true;

      // simple case: create new service
      if (!this.editMode) {
        db.collection(C.COLLECTION.SERVICE_CATALOG).doc()
          .set(this.editData, { merge: true })
          .then(() => {
            this.$refs["new-service-modal"].hide();
            toast.success("New service added to the catalogue.");
            this.isSaving = false;
          })
          .catch((error) => {
            toast.error("Couldn't create new service. " + error.message);
            this.isSaving = false;
          });
        return;
      }

      // edit mode - needs additional checks and flags to keep the prices and products in sync with stripe
      // TODO: there is probably a better way
      db.collection(C.COLLECTION.PRODUCTS).doc(C.PRODUCT_PREFIX + this.serviceId).get().then((snap) => {
        const product = snap.data();
        product.id = snap.id;

        // stripe sets it as string -,-
        const waitingForUpdate = (product.metadata.updateInProgress === true || product.metadata.updateInProgress === "true");
        if (waitingForUpdate ) {
          console.log("Stripe synchronization still in progress");
          toast.error("Not ready yet, please try again later");
          this.isSaving = false;
          return;
        }

        db.collection(C.COLLECTION.PRODUCTS).doc(C.PRODUCT_PREFIX + this.serviceId).update({ "metadata.updateInProgress": true }).then(() => {
          db.collection(C.COLLECTION.SERVICE_CATALOG).doc(this.serviceId).set(this.editData, { merge: true })
            .then(() => {
              this.$refs["new-service-modal"].hide();
              toast.success("Service updated.");
              this.isSaving = false;
            })
            .catch((error) => {
              toast.error("Couldn't udpate service. " + error.message);
              this.isSaving = false;
            });
        });
      })
    },
    deleteService(index) {
      if (confirm("Do you really want to delete this service?")) {
        var docRef = db
          .collection(C.COLLECTION.SERVICE_CATALOG)
          .doc(this.servicesList[index].id);
        docRef
          .delete()
          .then(() => {
            console.log("Service successfully deleted!");
          })
          .catch((error) => {
            console.error("Error removing service: ", error);
          });
      }
    },
    validate() {
      //Check first name
      this.bluredName = true;
      if (!this.validInput(this.editData.name)) {
        this.valid = false;
        return false;
      }
      this.bluredPrice = true;
      if (!this.validNonTxtField(this.editData.price)) {
        this.valid = false;
        return false;
      }
      this.bluredText = true;
      if (!this.validInput(this.editData.text)) {
        this.valid = false;
        return false;
      }

      this.valid = true;
      return true;
    },
    validInput(input) {
      return input.trim().length > 0;
    },
    validNonTxtField(input) {
      return input != null && input != "";
    },
    getModelName(service) {
      const model = this.watchModels.get(service.modelId);
      if (!model)
        return "";

      return model.name;
    }
  },
  mounted() {
    this.allServices();
    this.allWatchModels();
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/platform.scss";
</style>